<template>
  <div>
    <a-modal
      :title="studyId"
      :visible="visible"
      
      @ok="handleOk"
      @cancel="handleCancel"
      width="70%"
    >
      <div></div>
    </a-modal>
  </div>
</template>

<script>
// import { pageStudiesSensor } from '@/api/studiesSensor/studiesSensor'

export default {
  props: {
    studyId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk(e) {},
    handleCancel(e) {
      //   console.log('Clicked cancel button')
      this.visible = false
    },
  },
}
</script>

<style>
</style>